
import {
  PropType,
  getCurrentInstance,
  ref,
  toRefs
} from 'vue'
import {
  environment
} from '@/utils'
import { TDataCourierProfileCardProp } from './TCourierProfileCardProps'

export default {
  name: 'CourierProfileCard',
  props: {
    data: {
      type: Object as PropType<TDataCourierProfileCardProp>,
      default: null,
    },
    showStatus: {
      default: true
    },
    showPhone: {
      default: false
    },
    showInfoManifest: {
      default: true
    },
    isAssign: {
      default: false
    }
  },
  emits: ['assign'],
  setup(props: any, {
    emit
  }: any) {
    const app = getCurrentInstance()
    const {
      $convertPhoneNumber
    } = app!.appContext.config.globalProperties
    const dataCourier = toRefs(props).data
    // eslint-disable-next-line prefer-destructuring
    const isAssign = toRefs(props).isAssign
    const assign = (data: any) => {
      emit('assign', data)
    }
    const API_BASE = environment.getApiBase()

    function goWhatsApps(): void {
      // console.log('okoe cli', $convertPhoneNumber(dataCourier.value.Handphone))
      window.open(`${environment.getLinkWa()}${$convertPhoneNumber(dataCourier.value.Handphone)}`)
    }
    return {
      /* eslint-disable vue/no-dupe-keys */
      dataCourier,
      assign,
      isAssign,
      API_BASE,
      goWhatsApps
    }
  }
}
