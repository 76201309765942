import {
  ref,
  onMounted,
  getCurrentInstance,
  computed
} from 'vue'
import {
  courierUseCase
} from '@/domain/usecase'
import {
  useStore
} from 'vuex'
import {
  useRouter, useRoute
} from 'vue-router'
import lodash from 'lodash'
import CourierProfileCard from '@/views/master/courier/TrackingComponent/CourierProfileCard.vue'
import MapsTracking from '@/views/master/courier/tracking/components/MapsTracking.vue'
import GeolocationExample from '@/plugins/system/geolocation'

export default {
  name: 'courierTrackingForm',
  components: {
    CourierProfileCard,
    MapsTracking
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const dataCourier = ref([])
    const inputSearch = ref('')
    const filtersCourierOption = computed(() => dataCourier.value.filter((item: any) => item.Nama.toLowerCase().includes(inputSearch.value.toLowerCase())))
    const filter = {
      offset: 0,
      top: 10,
      skip: 0,
      expand: '&$expand=Kota($select=Id,Nama), TipeAgen($select=Id,Nama)'
    }
    const totalRecords = ref(0)
    const sliderActive = ref(false)
    const sendInputSearch = ref('')
    const dataForm = ref(null)
    const op = ref()
    const app = getCurrentInstance()
    const route = useRoute()
    const dataOneProfile = ref(null)
    const currentLatLng: any = ref({
      latitude: store.getters['appActiveUser/getAgent'].latitude ?? 0,
      longitude: store.getters['appActiveUser/getAgent'].longitude ?? 0
    })
    const showMaps = ref(false)

    const {
      $confirm,
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties

    const getAgent = async () => {
      store.dispatch('showLoading')
      const response = await courierUseCase.getListAssignCourier({
        // custom: `ClockIn ge ${moment().format('YYYY-MM-DD')} and ClockIn le ${moment().format('YYYY-MM-DD')} and MsAgenIdIn eq ${store.state.appActiveUser.agent.AgenId}`
        // custom: 'ClockIn ge 2022-01-21 and clockin le 2022-01-21 and MsAgenIdIn eq 10014',
        // select: '&$select=KurirMsUserId,Id',
        // expand: '&$expand=KurirMsUser'
        // PageNumber: `?PageNumber=${filter.skip}`,
        // PageSize: `&PageSize=${filter.top}`
        AgenId: `?AgenId=${store.getters['appActiveUser/getAgent']?.id}`
      })
      if (!response.error) {
        dataCourier.value = await response.result.Data.map((x: any) => {
          x.Handphone = x.Phone
          x.Nama = x.Fullname
          x.Kapasitas = x.Kendaraan?.KurirMsJenisKendaraan?.Kapasitas
          return x
        })
        showMaps.value = true
        if (dataCourier.value.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          dataOneProfile.value = dataCourier.value[0]
        }
      }
      store.dispatch('hideLoading')
    }
    const onPage = (event: any) => {
      filter.skip = event.page * filter.top
      getAgent()
    }
    const searchData = async (val: any) => {
      // console.log('search Data', val)
      sendInputSearch.value = val.toLowerCase()
      await getAgent()
    }
    const redirectAdd = () => {
      router.push({
        name: 'master-courier-tambah'
      })
    }
    const showDropDown = (evt: any, data: any) => {
      // console.log('masuk click drop', data, evt)
      dataForm.value = data
      op.value.toggle(evt)
    }
    const editData = (data: any) => {
      // console.log('data edit', router)
      console.log('data editData', data)
      const IdCourier = data.Id
      router.push({
        name: 'master-courier-edit',
        params: {
          courierId: IdCourier
        }
      })
    }
    const trackingFormCourier = () => {
      // console.log('data edit', router)
      // console.log('data editData', data)
      // const IdCourier = data.Id
      router.push({
        name: 'master-courier-tracking',
        // params: {
        //   courierId: IdCourier
        // }
      })
    }
    const deleteData = (Id: number) => {
      $confirm.require({
        header: 'Agent',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const response = await courierUseCase.deleteData(Id)
          if (response.error) {
            $toast.add({
              severity: 'error',
              detail: $strInd.toast.errorDelete,
              group: 'bc',
              life: 3000
            })
          } else {
            $toast.add({
              severity: 'success',
              detail: $strInd.toast.successDeleteData,
              group: 'bc',
              life: 3000
            })
            getAgent()
          }
        }
      })
    }
    const isActived = async (val: any) => {
      store.dispatch('showLoading')
      const response = await courierUseCase.changeIsActive({
        Id: val.Id,
        IsActive: val.IsActive
      })

      if (response.error) {
        val.IsActive = !val.IsActive
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 1500
        })
      } else {
        $toast.add({
          severity: 'success', detail: 'Berhasil merubah status aktif', group: 'bc', life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    const changeIsActive = (val: any) => {
      $confirm.require({
        header: 'Status Aktif',
        message: 'Apakah anda ingin menggantinya?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          isActived(val)
        },
        reject: () => {
          val.IsActive = !val.IsActive
        }
      })
    }

    const setCardProfile = async (data: any) => {
      showMaps.value = await false
      dataOneProfile.value = await data
      showMaps.value = true
    }

    const openForm = ref(false)

    const getCurrentLocation = async () => {
      console.log('tergantung', !currentLatLng.value.latitude && !currentLatLng.value.longitude)
      if (!currentLatLng.value.latitude && !currentLatLng.value.longitude) {
        const response = await GeolocationExample.getCurrentPosition()
        currentLatLng.value.latitude = response.latitude
        currentLatLng.value.longitude = response.longitude
        console.log('lant', currentLatLng.value)
      }
      getAgent()
    }

    onMounted(() => {
      getCurrentLocation()
    })
    return {
      dataCourier,
      getAgent,
      filter,
      store,
      totalRecords,
      onPage,
      sliderActive,
      inputSearch,
      sendInputSearch,
      searchData,
      router,
      redirectAdd,
      dataForm,
      op,
      showDropDown,
      editData,
      deleteData,
      changeIsActive,
      app,
      route,
      lodash,
      openForm,
      trackingFormCourier,
      dataOneProfile,
      currentLatLng,
      showMaps,
      filtersCourierOption
    }
  }
}
