
/* eslint-disable */
import {
  computed,
  ref,
  onMounted,
  onUnmounted,
  watch,
  getCurrentInstance,
  toRefs,
  onBeforeUnmount
} from 'vue'
import {
  Loader
} from '@googlemaps/js-api-loader'
// import GeolocationExample from "@/plugins/system/geolocation"
import {
  cloneDeep
} from 'lodash'

export default {
  name: 'MapsTracking',
  props: {
    listMarker: {
      default: () => []
    },
    currentLocation: {
      default: () => {}
    }
  },
  setup(props: any) {
    const app = getCurrentInstance()
    const {
      $socketHub
    } = app!.appContext.config.globalProperties
    const otherPos = ref(null)
    const mapDiv = ref(null)
    let markers: google.maps.Marker[] = []
    let map: google.maps.Map
    const currentLatLng = ref(null) as any
    const {
      listMarker,
      currentLocation
    } = toRefs(props)
    console.log('version', google.maps.version)
    // const content = () => ({
    //   template: Components
    // }) as

    const contentString =
      ref('<div id="content">' +
        '<div id="siteNotice">' +
        "</div>" +
        '<h1 id="firstHeading" class="firstHeading">Uluru</h1>' +
        '<div id="bodyContent">' +
        "<p><b>Uluru</b>, also referred to as <b>Ayers Rock</b>, is a large " +
        "sandstone rock formation in the southern part of the " +
        "Northern Territory, central Australia. It lies 335&#160;km (208&#160;mi) " +
        "south west of the nearest large town, Alice Springs; 450&#160;km " +
        "(280&#160;mi) by road. Kata Tjuta and Uluru are the two major " +
        "features of the Uluru - Kata Tjuta National Park. Uluru is " +
        "sacred to the Pitjantjatjara and Yankunytjatjara, the " +
        "Aboriginal people of the area. It has many springs, waterholes, " +
        "rock caves and ancient paintings. Uluru is listed as a World " +
        "Heritage Site.</p>" +
        '<p>Attribution: Uluru, <a href="https://en.wikipedia.org/w/index.php?title=Uluru&oldid=297882194">' +
        "https://en.wikipedia.org/w/index.php?title=Uluru</a> " +
        "(last visited June 22, 2009).</p>" +
        "</div>" +
        "</div>") as any
    const infowindow = new google.maps.InfoWindow({
      content: contentString.value,
      maxWidth: 200
    })
    console.log('help ', currentLocation.value)
    const markerPosition = ref(listMarker.value.map((x: any, index: any) => {
      x.position = {
        lat: currentLocation.value.latitude,
        lng: currentLocation.value.longitude
      }
      x.label = x.Phone
      x.title = x.Fullname
      x.username = x.Username
      x.idKurir = x.Id
      x.icon = {
        url: require('@/assets/img/icon/maps/kurir-wheel-2.png'),
        scaledSize: {
          width: 25,
          height: 50
        },
      }
      return x
    }))
    // const mappingMarker = (data: any) => {
    //   console.log('data sen', data)
    //   console.log('current loca', currentLocation.value)
    //   data.position = {
    //     lat: currentLocation.value.latitude,
    //     lng: currentLocation.value.longitude
    //   }
    //   data.label = data.Phone
    //   data.title = data.Fullname
    //   data.idKurir = data.Id
    //   data.username = data.Username
    //   data.icon = {
    //     url: require('@/assets/img/icon/car_icon.png'),
    //     scaledSize: {
    //       width: 25,
    //       height: 50
    //     },
    //   }
    //   return data
    // }

    // const markerPosition = ref(mappingMarker(listMarker.value))

    const updateData = async (list: any, data: any) => {
      markerPosition.value = await cloneDeep(list)
      await clearMarkers()
      for (let i = 0; i < markerPosition.value.length; i++) {
        // console.log('before masuk if', markerPosition.value[i].position.lat)
        if (markerPosition.value[i].username === data.Username) {
          // console.log('masuk if', data)
          markerPosition.value[i].position.lat = data.Lat === null ? currentLocation.value.latitude : Number(data.Lat)
          markerPosition.value[i].position.lng = data.Long === null ? currentLocation.value.longitude : Number(data.Long)
          markerPosition.value[i].tempPosition.lat = data.Lat === null ? currentLocation.value.latitude : Number(data.Lat)
          markerPosition.value[i].tempPosition.lng = data.Long === null ? currentLocation.value.longitude : Number(data.Long)
        } else {
          // console.log('masuk else if', markerPosition.value[i])
          markerPosition.value[i].position.lat = markerPosition.value[i].tempPosition.lat
          markerPosition.value[i].position.lng = markerPosition.value[i].tempPosition.lng
        }
        addedMarker(markerPosition.value[i])
      }
    }

    const dataReturn = (data: any) => {
      return {
        position: {
          lat: Number(data.position.lat),
          lng: Number(data.position.lng)
        },
        tempPosition: {
          lat: Number(data.position.lat),
          lng: Number(data.position.lng)
        },
        icon: data.icon,
        // shape: shape,
        title: data.title,
        idKurir: data.idKurir,
        username: data.username,
        map,
        // animation: google.maps.Animation.DROP,
      }
    }

    function addedMarker(data: any): void {
      markers.push(
        new google.maps.Marker(dataReturn(data))
      )
    }

    function clearMarkers(): void {
      for (let i = 0; i < markers.length; i++) {
        markers[i].setMap(null);
      }
      markers = [];
    }

    const initMap = async () => {
      map = new google.maps.Map(document.getElementById("mapDiv") as HTMLElement, {
        center: {
          lat: currentLocation.value.latitude,
          lng: currentLocation.value.longitude
        },
        zoom: 10,
      }) as any
      for (let i = 0; i < markerPosition.value.length; i++) {
        addedMarker(markerPosition.value[i])
      }
    }

    const socketChangeHub = (val: any) => {
      if (checkingList(markers, val) === true) {
        updateData(markers, val)
      }
    }

    function checkingList(list: any, val: any): any {
      for (let i = 0; i < list.length; i++) {
        if (list[i].username === val.Username) {
          return true
        }
      }
      return false
    }

    function testUpdate(): void {
      updateData(markers, null)
    }

    onMounted(() => {
      initMap()
      $socketHub.on('lokasikurir', socketChangeHub)
    })

    onBeforeUnmount(() => {
      $socketHub.off('lokasikurir')
    })
    return {
      otherPos,
      mapDiv,
      testUpdate
    }
  }
}
