import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-3 gap-4" }
const _hoisted_2 = {
  key: 0,
  class: "col-span-2 ..."
}
const _hoisted_3 = {
  key: 1,
  class: ""
}
const _hoisted_4 = { class: "section-courier" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MapsTracking = _resolveComponent("MapsTracking")
  const _component_InputIconRightGrey = _resolveComponent("InputIconRightGrey")
  const _component_CourierProfileCard = _resolveComponent("CourierProfileCard")

  return (_openBlock(), _createBlock("section", null, [
    _createVNode("div", _hoisted_1, [
      true
        ? (_openBlock(), _createBlock("div", _hoisted_2, [
            (_ctx.currentLatLng && _ctx.showMaps)
              ? (_openBlock(), _createBlock(_component_MapsTracking, {
                  key: 0,
                  listMarker: _ctx.dataCourier,
                  currentLocation: _ctx.currentLatLng,
                  style: {"border-radius":"12px","border":"6px solid #FFFFFF","box-sizing":"border-box"}
                }, null, 8, ["listMarker", "currentLocation"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      true
        ? (_openBlock(), _createBlock("div", _hoisted_3, [
            (_ctx.dataCourier.length > 0)
              ? (_openBlock(), _createBlock(_component_InputIconRightGrey, {
                  key: 0,
                  modelValue: _ctx.inputSearch,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.inputSearch = $event)),
                  class: "mb-5",
                  placeholder: "Cari Disini ...",
                  inputBackground: '#ffffff',
                  type: _ctx.$enumBase.input.text,
                  icon: _ctx.$icon.svg.searchEva
                }, null, 8, ["modelValue", "type", "icon"]))
              : _createCommentVNode("", true),
            _createVNode("div", _hoisted_4, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.filtersCourierOption, (data, index) => {
                return (_openBlock(), _createBlock(_component_CourierProfileCard, {
                  data: data,
                  key: index,
                  class: "mb-3",
                  isAssign: false,
                  onClick: ($event: any) => (_ctx.setCardProfile(data))
                }, null, 8, ["data", "onClick"]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}